// Button Mixins
@mixin btn-unfocus($var) {
  padding: .5rem 1.5rem;
  background-color: lighten($var, 50%);
  color: $var;
  &:hover {
    background-color: $var;
    color: $white;
  }
}
@mixin btn-link($var) {
  background-color: transparent;
  padding: 0;
  transform: scale(1.02);
  color: $var;
  &::after {
    position: absolute;
    content: "";
    bottom: -0.3rem;
    left: 50%;
    height: 2px;
    width: 0%;
    transform: translateX(-50%);
    transition: .2s;
  }
  &:hover,
  &:focus {
    &::after {
      width: 100%;
    }
  }
  &:hover {
    color: darken($var, 2%);
  }
  &::after {
    background-color: $var;
  }
}
// Animation Mixins
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}
@mixin blurUp(){

  @keyframes moveUp {
    0% {
        filter: blur(.3rem);
        transform: translateY(75px);
        line-height: 100px;
        opacity: 0;
    }

    100% {
        filter: blur(0);
        transform: translate(0);
        line-height: 77px;
        opacity: 1;
    }

  }
  animation-name: moveUp;

}
@mixin blurUpInfo(){

  @keyframes moveUpInfo {
  0% {
      filter: blur(1rem);
      transform: translateY(150px);
  }
  
  100% {
      filter: blur(0);
      transform: translate(0);
  }
  
  }
  animation-name: moveUpInfo;
}
// Styling Mixins
 @mixin bgc($color) {
   background-color: $color;
   border: darken($color, 10%);

   &:hover {
     background-color: darken($color, 10%);
     border: darken($color, 12%);
   }

   &:focus {
     box-shadow: 0 0 0 0.1rem darken($color, 10%);
   }

   &-inverse {
     background-color: transparent;
     color: $color;
     border: 0;
   }
 }

/* Prefix mixin - https://css-tricks.com/snippets/sass/mixin-prefix-properties */
// Mixin to prefix several properties at once
// @example - @include prefix((transform: translate(240%, 0)), webkit moz o ms);
// @author Hugo Giraudel
// @param {Map} $declarations - Declarations to prefix
// @param {List} $prefixes (()) - List of prefixes to print
// @mixin prefix($declarations, $prefixes: ()) {
//   @each $property, $value in $declarations {
//     @each $prefix in $prefixes {
//       #{'-' + $prefix + '-' + $property}: $value;
//     }
//     // Output standard non-prefixed declaration
//     #{$property}: $value;
//   }
// }