.ais-InstantSearch {
  margin-top: 1rem;

  @include media-breakpoint-up(md) {
    margin-top: 2rem;
  }

  .display-card {
      position: relative;
      width: 100%;
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2.5rem;
      border-radius: .5rem;
      border: solid 1px $light-gray;
      overflow: hidden;

      @include media-breakpoint-up(md) {
        max-width: 30%;
        margin-left: initial;
        margin-right: 5%;
        margin-bottom: 4rem;

        @include media-breakpoint-up(md) {
          &:nth-child(3n) {
            margin-right: 0;
          }
        }
      }

      &-content {
        padding: 1rem;
        margin-bottom: 3rem;

        .btn {
          position: absolute;
          width: calc(100% - 2rem);
          bottom: 1rem;
        }
      }
    }


}