// Libraries
@import "./abstracts/variables";
@import "~bootstrap/scss/bootstrap";

// Abstracts
@import "./abstracts/mixins";
@import "./abstracts/animations";

// Utils
@import "./util/rotate";

// Modules
@import "./base/common";
@import "./base/typography.scss";

// Components
@import "./components/avatars";
@import "./components/buttons";
@import "./components/inputs";

// Layout
@import "./layouts/layout";


/* ------------- Default Root Routes  ---------------- */

// Libraries
// @import "../abstracts/variables";
// @import "~bootstrap/scss/bootstrap";

// // Abstracts
// @import "../abstracts/mixins";
// @import "../abstracts/animations";

// // Utils
// @import "../util/rotate";
// @import "../util/instant-search";
// @import "../util/slickslider";

// // Modules
// @import "../base/common";
// @import "../base/typography.scss";

// // Components
// @import "../components/avatars";
// @import "../components/badges";
// @import "../components/buttons";
// @import "../components/inputs";

// // Layout
// @import "../layouts/layout";
