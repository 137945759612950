@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

header {

	&, .header-container {
		height: 50px;
		overflow: hidden;

		@include media-breakpoint-up(lg) {
			height: 72px;
		}
	}

	.header-container {
		width: 100%;
		background: rgba(0, 0, 0, 0.93);
		color: #ffffff;
		transition: height 500ms ease-out;

		.header-wrap {
			margin-top: 9px;

			@include media-breakpoint-up(lg) {
				height: 72px;
				margin-top: 0;
				padding-top: 14px;
			}
		}

	}

	.site-logo {
			display: inline-block;

			img {
				height: 28px;
				@include media-breakpoint-up(lg) {
					height: 48px;
				}
			}

			a {
				position: relative;
				display: block;

				h1 {
					margin: 0;
				}
			}

		}

}