@import '../abstracts/variables.scss';

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1rem;
  user-select: none;

  &:hover input[type="checkbox"] ~ .checkmark {
    background-color: #ccc;
  }

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  input[type="checkbox"]:checked ~ .checkmark {
    background-color: $primary;
  }
  input[type="checkbox"]:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 8px;
    top: 4px;
    width: 5px;
    height: 11px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}
.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: .25rem;
  background-color: #eee;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}

.floating-label-wrapper {
  position: relative;
  display: inline-block;
  label {
    position: absolute;
    top: 0;
    left: 0;
    transition: .2s;
    z-index: -1;
  }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="password"] {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid $secondary;
    padding: .3rem .5rem;
    &:focus + label {
      top: -1rem;
      font-size: .8rem;
    }
  }
}